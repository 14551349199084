var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-2 mb-2 px-1",class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
        ? 'flex-column justify-center'
        : 'align-center'},[_c('v-spacer'),_c('v-menu',{attrs:{"min-width":"230","nudge-bottom":"6px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":"","color":"#ffe7b8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize pa-4",staticStyle:{"background-color":"rgb(235, 235, 234)","color":"#fff"},attrs:{"depressed":"","light":"","outlined":"","ripple":false,"height":"5px","x-small":"","dense":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.getSelectedDateRange)+" "),_c('v-icon',{attrs:{"small":"","dense":"","color":"black"}},[_vm._v("mdi-chevron-down")])],1),(_vm.getSelectedDateRange)?_c('v-badge',{staticClass:"mb-6",staticStyle:{"margin-right":"-10px"},attrs:{"color":"#2e3995","dot":""}}):_vm._e()],1)]}}],null,true)},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.getSelectedDateRange))])])],1)]}}])},[_c('div',[_c('v-list',_vm._l((_vm.dateRange),function(list){return _c('div',{key:list.value,staticClass:"date-range-dropdown pointer d-flex pa-2",class:_vm.getSelectedDateRange == list.title ? 'slectedDateRange' : '',on:{"click":function($event){return _vm.selectDate(list.value)}}},[_c('div',{staticClass:"date-range-text-color"},[_vm._v(_vm._s(list.title))]),_c('v-spacer'),(_vm.getSelectedDateRange == list.title)?_c('v-icon',{attrs:{"size":"20","color":"#2e3995"}},[_vm._v(" mdi-checkbox-marked")]):_vm._e()],1)}),0)],1)])],1),_c('v-dialog',{ref:"dateModal",attrs:{"persistent":"","width":"290px"},model:{value:(_vm.dateModal),callback:function ($$v) {_vm.dateModal=$$v},expression:"dateModal"}},[_c('v-date-picker',{attrs:{"color":"#2e3995","no-title":"","scrollable":"","multiple":"","range":true,"min":"2022-01-01","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#2e3995","outlined":"","x-small":""},on:{"click":function($event){_vm.dateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#2e3995","dark":"","x-small":""},on:{"click":function($event){_vm.dateModal = false;
          _vm.applyCustomRange(_vm.picker);}}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }