<template>
  <div> <Users /></div>
</template>
<script>
export default {
  data: () => ({
   
  }),
  components:{
    Users :()=>import("../../components/users/users.vue"),
   
  },
  mounted() {
    
  },
  methods: {
  }
};
</script>
