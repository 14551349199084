<template>
  <div> <Dashboard /></div>
</template>
<script>
export default {
  data: () => ({
   
  }),
  components:{
    Dashboard :()=>import("../../components/Dashboard/dashboard.vue"),
   
  },
  mounted() {
    
  },
  methods: {
  }
};
</script>
