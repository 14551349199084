import { render, staticRenderFns } from "./no-order.vue?vue&type=template&id=4e288310&scoped=true&"
var script = {}
import style0 from "./no-order.vue?vue&type=style&index=0&id=4e288310&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e288310",
  null
  
)

export default component.exports