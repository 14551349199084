<template>
  <div> <Purchases /></div>
</template>
<script>
export default {
  data: () => ({
   
  }),
  components:{
    Purchases :()=>import("../../components/purchases/purchases.vue"),
   
  },
  mounted() {
    
  },
  methods: {
  }
};
</script>
