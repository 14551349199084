<template>
      <div> <Customers /></div>
</template>
<script>
export default {
  data: () => ({
   
  }),
  components:{
    Customers :()=>import("../../components/customers/customers.vue"),
   
  },
  mounted() {
    
  },
  methods: {
  }
};
</script>
